import React from "react"
import Layout from "../components/layout"

const Contact = () => (
	<Layout>
		<p>Contact Page</p>
	</Layout>
)

export default Contact
